import React, { lazy, Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import T from 'prop-types';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { GlobalLoadingProvider } from '@devseed-ui/global-loading';
import { DevseedUiThemeProvider } from '@devseed-ui/theme-provider';

import GlobalStyle from './styles/global';
import { themeOverridesOCI } from './styles/theme';
import './main.css';

// Views
const UhOh = lazy(() => import('./components/uhoh'));
const ErrorBoundary = lazy(() => import('./components/uhoh/fatal-error'));
const Home = lazy(() => import('./components/home'));
const AboutUs = lazy(() => import('./components/aboutus'));
const DemoVideos = lazy(() => import('./components/demo-videos'));
const Models = lazy(() => import('./components/models'));
const TermsOfUse = lazy(() => import('./components/terms-use'));
const Methodology = lazy(() => import('./components/methodology'));
const TotalEmissions = lazy(() => import('./components/total-emissions'));
const SupplyChain = lazy(() => import('./components/supply-chain/index'));
const EnvironmentalJustice = lazy(
  () => import('./components/environmental_justice/index')
);
const Analysis = lazy(() => import('./components/analysis/index'));
const MapPage = lazy(() => import('./components/map'));
const OilDetail = lazy(() => import('./components/oil/index'));
const CompareOil = lazy(() => import('./components/compare-oil/index'));
const Glossary = lazy(() => import('./components/glossary'));
const EconomicCrudeGrade = lazy(
  () => import('./components/economic/crude-grade')
);
const EconomicBreakevenPrice = lazy(
  () => import('./components/economic/breakeven-price')
);

// const Scenarios = lazy(() => import('./components/scenarios/hub'));
// const EachScenario = lazy(() => import('./components/scenarios/single'));
const Studies = lazy(() => import('./components/studies'));

// Contexts
import { BaseDataProvider } from './context/base-data';
import { PageLoading } from '$components/page-loading';

const composingComponents = [
  // Add contexts here.
  ErrorBoundary,
  BaseDataProvider
];

function ScrollTop() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (hash) {
      return;
    }

    window.scrollTo({ top: 0 });
  }, [hash, pathname]);
  return null;
}

// Root component.
function Root() {
  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     '--scrollbar-width',
  //     window.innerWidth - document.documentElement.clientWidth + 'px'
  //   );
  // }, []);

  useEffect(() => {
    // Hide the welcome banner.
    const banner = document.querySelector('#welcome-banner');
    if (banner) {
      banner.classList.add('dismissed');
      setTimeout(() => banner.remove(), 500);
    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollTop />
      <DevseedUiThemeProvider theme={themeOverridesOCI}>
        <GlobalLoadingProvider />

        <GlobalStyle />
        <Composer components={composingComponents}>
          <Suspense fallback={<PageLoading />}>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about/demovideos' element={<DemoVideos />} />
              <Route path='/about/models' element={<Models />} />
              <Route path='/about/termsofuse' element={<TermsOfUse />} />
              <Route path='/about/us' element={<AboutUs />} />
              <Route path='/about/studies' element={<Studies />} />
              <Route path='/methodology' element={<Methodology />} />
              <Route path='/total-emissions' element={<TotalEmissions />} />
              <Route path='/supply-chain' element={<SupplyChain />} />
              <Route path='/analysis' element={<Analysis />} />
              <Route
                path='/flaring-risk-map'
                element={<EnvironmentalJustice />}
              />
              <Route
                path='/economics/benchmark-crude'
                element={<EconomicCrudeGrade />}
              />
              <Route
                path='/economics/breakeven-price-of-production'
                element={<EconomicBreakevenPrice />}
              />
              <Route path='/map' element={<MapPage />} />

              <Route path='/oil/:oilId' element={<OilDetail />} />
              <Route
                path='/compare/:oilId/:oilToCompareId'
                element={<CompareOil />}
              />
              <Route path='/glossary' element={<Glossary />} />
              <Route path='*' element={<UhOh />} />
            </Routes>
          </Suspense>
        </Composer>
      </DevseedUiThemeProvider>
    </BrowserRouter>
  );
}

const container = document.getElementById('app-container');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);

/**
 * Composes components to to avoid deep nesting trees. Useful for contexts.
 *
 * @param {node} children Component children
 * @param {array} components The components to compose.
 */
function Composer(props) {
  const { children, components } = props;
  const itemToCompose = [...components].reverse();

  return itemToCompose.reduce(
    (acc, Component) => <Component>{acc}</Component>,
    children
  );
}

Composer.propTypes = {
  components: T.array,
  children: T.node
};

Object.defineProperty(Array.prototype, 'last', {
  enumerable: false,
  configurable: true,
  get: function () {
    return this[this.length - 1];
  },
  set: undefined
});
